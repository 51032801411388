<template>
  <div class="process-template">
    <PageTop
      :addShow="false"
      :processShow="true"
      @addProcess="addProcess"
      @batchAdd="batchAdd"
      @changeCropItems="changeCropItems"
    >
      <div class="adTitle" slot="adTitle">流程模板配置</div>
    </PageTop>
    <div class="template-main animated fadeInUp">
      <MyProcessLine :tableData="tableData"></MyProcessLine>
      <div class="table-box">
        <MyTable
          :tableData="tableData"
          :configTable="configTable"
          @handleDelete="handleDelete"
          @handleEdit="handleEdit"
        ></MyTable>
      </div>
    </div>
    <el-dialog
      class="big-pop adds"
      title=" "
      :visible.sync="addShow"
      width="671px"
      :before-close="setAddClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="big-pop-main">
        <div class="ware-title">
          <div class="left-line"></div>
          选择已有模板
        </div>
        <div class="process-box">
          <MyProcessLine
            ref="bulkAdd"
            :checkShow="true"
            :tableData="tableData"
          ></MyProcessLine>
        </div>
        <div class="dialog-footer">
          <el-button class="ok-button" type="primary" @click="okAdd"
            >确 定</el-button
          >
          <el-button class="no-button" @click="noAdd">取 消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 新增/编辑窗口 -->
    <el-dialog
      class="small-pop"
      title=" "
      :visible.sync="batchShow"
      width="598px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="openDialog"
    >
      <div class="small-pop-main">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ popTitle }}
        </div>
        <el-form
          :model="form"
          ref="form"
          :rules="isRules"
          hide-required-asterisk
          class="my-el-form"
        >
          <el-form-item
            label="流程名称"
            :label-width="formLabelWidth"
            prop="title"
          >
            <el-input
              v-model.trim="form.title"
              autocomplete="off"
              placeholder="请输入流程名称"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="状态"
            :label-width="formLabelWidth"
            prop="custom_state"
          >
            <el-switch class="mySwitch" v-model="form.custom_state">
            </el-switch>
          </el-form-item>
          <el-form-item
            label="排序"
            :label-width="formLabelWidth"
            prop="weight"
          >
            <!-- type="number" -->
            <el-input
              v-model.trim.number="form.weight"
              autocomplete="off"
              placeholder="请输入排序权重"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="标签" :label-width="formLabelWidth" prop="tag">
            <div class="form-tips">
              <div class="form-tips-list">
                <div class="form-tip-item" v-for="(t, i) in form.tag" :key="i">
                  <span>{{ t }}</span>
                  <img
                    src="../../assets/image/task/icon3.png"
                    @click="delMeanItem(i)"
                  />
                </div>
                <div
                  class="add-tip-button"
                  @click="showInput"
                  v-show="!isInputShow"
                >
                  <span>+</span><span>添加</span>
                </div>
              </div>
              <el-input
                class="tip-input"
                autofocus
                @blur="getBlur"
                v-show="isInputShow"
                v-model.trim="tipVal"
                maxlength="20"
              ></el-input>
              <div class="img-box">
                <img
                  class="icon2-imgs"
                  v-show="isInputShow"
                  src="../../assets/image/task/icon2.png"
                  @click="addDetermine"
                />
              </div>
            </div>
          </el-form-item>

          <el-form-item label=" " class="dialog-footer">
            <el-button class="ok-button" type="primary" @click="okWarehouse"
              >确 定</el-button
            >
            <el-button class="no-button" @click="noWarehouse">取 消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import {
  getFlowListApi,
  getFlowInfoApi,
  postFlowEditApi,
  delFlowApi,
  postAddFlowApi,
  postAddsFlowApi,
} from "@/request/api";
import { mapState } from "vuex";

export default {
  components: {
    MyProcessLine: () => import("@/components/MyProcessLine.vue"),
    PageTop: () => import("@/components/PageTop.vue"),
    MyTable: () => import("@/components/MyTable.vue"),
  },
  data() {
    return {
      addShow: false,
      batchShow: false,
      isInputShow: false,
      tipVal: "",
      popTitle: "",
      total: 20, 
      last_page: 1, 
      per_page: 10, 
      current_page: 1, 
      configTable: [
        {
          prop: "title",
          label: "流程名称",
          width: "",
        },

        {
          prop: "custom_state",
          label: "状态",
          width: "",
        },
        {
          prop: "weight",
          label: "排序",
          width: "",
        },

        {
          prop: "",
          label: "操作",
          width: "",
          editType: true,
          delType: true,
        },
      ],
      tableData: [],
      formLabelWidth: "100px",
      form: {
        tag: [],
        custom_state: false,
        title: "",
        weight: "",
      },
      isRules: {
        title: [{ required: true, message: "请输入流程名称", trigger: "blur" }],
        weight: [
          { required: true, message: "请输入排序权重", trigger: "blur" },
          {
            type: "number",
            message: "请填写数字",
            trigger: ["change", "blur"],
          },
        ],
      },
      flow_id: "",
      cropClassList: [],
      cropItemsList: [],
      product_id: undefined,
    };
  },
  watch: {
        addShow(n) {
      if (!n) {
        this.$refs.bulkAdd.selectLength = [];
        this.tableData.forEach((item) => {
          item.checked = false;
        });
      }
    },
    isInputShow(n) {
      if (!n) {
        this.tipVal = "";
      }
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.getFlowListApi();
  },
  methods: {
    changeCropItems(id) {
      this.product_id = id;
      this.getFlowListApi();
    },
    async postAddFlowApi() {
      this.form.weight = +this.form.weight;
      const { code } = await postAddFlowApi({
        manor_id: this.farmID,
        product_id: this.product_id,
        ...this.form,
      });
      if (code === 0) {
        this.getFlowListApi();
        this.$notify({
          title: "成功",
          message: "已添加",
          type: "success",
        });
        this.batchShow = false;
        this.form = { tag: [], custom_state: false, title: "", weight: "" };
        this.isInputShow = false;
      }
    },
    async postFlowEditApi() {
      this.form.weight = +this.form.weight;
      const { code } = await postFlowEditApi({
        flow_id: this.flow_id,
        ...this.form,
      });
      if (code === 0) {
        this.getFlowListApi();
        this.$notify({
          title: "成功",
          message: "已修改",
          type: "success",
        });
        this.batchShow = false;
        this.form = {};
      }
    },
    async getFlowInfoApi() {
      const { code, results } = await getFlowInfoApi({
        flow_id: this.flow_id,
      });
      if (code === 0) {
        this.form = results;
        this.form.custom_state = this.form.custom_state === 1 ? true : false;
      }
    },
    async getFlowListApi() {
      showFullScreenLoading();
      const { code, results } = await getFlowListApi({
        manor_id: this.farmID,
        product_id: this.product_id,
      });
      if (code === 0) {
        this.tableData = results;
        this.tableData.forEach((item) => {
          item.custom_state = item.custom_state === 1 ? "启用" : "禁用";
        });
      }
      tryHideFullScreenLoading();
    },
    getBlur() {
      if (!this.tipVal.trim()) {
        this.isInputShow = false;
      }
    },
    openDialog() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    showInput() {
      this.isInputShow = true;
    },
    addDetermine() {
      if (this.tipVal.trim()) {
        this.form.tag.push(this.tipVal);
        this.tipVal = "";
      } else {
        this.$notify.error({
          title: "错误",
          message: "请填写内容",
        });
      }
    },
    delMeanItem(index) {
      this.form.tag.splice(index, 1);
    },
    okWarehouse() {
      this.$refs["form"].validate((valid) => {
        if (!valid) return;
        if (this.popTitle === "新增流程") {
          this.postAddFlowApi();
        } else {
          this.postFlowEditApi();
        }
      });
    },
    noWarehouse() {
      this.batchShow = false;
      this.form = { tag: [], custom_state: false, title: "", weight: "" };
    },
    noAdd() {
      this.addShow = false;
    },
    async okAdd() {
      const { code } = await postAddsFlowApi({
        manor_id: this.farmID,
        product_id: this.product_id,
        ftids: this.$refs.bulkAdd.selectLength,
      });
      if (code === 0) {
        this.getFlowListApi();
        this.$notify({
          title: "成功",
          message: "已添加",
          type: "success",
        });
      }
      this.addShow = false;
    },
    setAddClose() {
      this.addShow = false;
    },
    handleClose() {
      this.batchShow = false;
      this.form = { tag: [], custom_state: false, title: "", weight: "" };
    },
    addProcess() {
      if (!this.product_id) {
        this.$notify.error({
          title: "错误",
          message: "请选择产品",
        });
        return;
      }
      this.popTitle = "新增流程";
      this.batchShow = true;
    },
    async batchAdd() {
      if (!this.product_id) {
        this.$notify.error({
          title: "错误",
          message: "请选择产品",
        });
        return;
      }
      const { code, results } = await getFlowListApi({
        manor_id: this.farmID,
        product_id: 0,
      });
      if (code === 0) {
        this.tableData = results;
        this.tableData.forEach((item) => {
          item["checked"] = false;
          item.custom_state = item.custom_state === 1 ? "启用" : "禁用";
        });
      }
      this.addShow = true;
    },
    handleEdit({ id }) {
      this.flow_id = id;
      this.getFlowInfoApi();
      this.popTitle = "编辑流程";
      this.batchShow = true;
    },
    async delFlowApi() {
      const { code } = await delFlowApi({
        flow_ids: [this.flow_id],
      });
      if (code === 0) {
        this.getFlowListApi();
        this.$notify({
          title: "成功",
          message: "已删除",
          type: "success",
        });
      }
    },
    handleDelete({ id }) {
      this.flow_id = id;
      this.$confirm("此操作将删除该流程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delFlowApi();
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    addTemplate() {
      this.addShow = true;
    },
  },
};
</script>

<style lang="less" scoped>
.process-template {
  width: calc(100% - 450px);
  flex-direction: column;
  display: flex;
  .land-admin {
    margin-top: 0;
  }
  .template-main {
    width: 100%;

    display: flex;
    .process-line {
      width: 597px;
    }
    .table-box {
      width: calc(100% - 597px);

      height: 100%;
      padding: 38px 49px;
      background-color: #0e2138;
      /deep/.el-table {
        width: 100%;
      }
    }
  }
  .big-pop,
  .small-pop {
    /deep/.el-dialog {
      width: 598px !important;
      background-color: inherit;
      margin-top: 0 !important;
      .el-dialog__header {
        padding-top: 5px;
      }
    }

    /deep/.el-dialog__body {
      padding: 0;
    }
    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      font-weight: bold;
      top: 59px;
      right: 51px;
    }
    /deep/.el-form-item {
      margin-bottom: 32px;
    }

    /deep/.el-button span {
      color: #fff;
    }
    /deep/.dialog-footer {
      margin-top: 40px;
      padding: 0 0 0 100px;
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }
    .big-pop-main {
      width: 671px;
      height: 860px;
      background: url(../../assets/image/configure/process/changebgimg.png)
        no-repeat center center;
      background-size: cover;
      padding: 45px 45px 0 45px;

      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }
      .process-box {
        width: 100%;
        height: 77%;
      }
    }
    .small-pop-main {
      width: 598px;
      height: 539px;
      background: url(../../assets/image/configure/process/addbgimg.png)
        no-repeat center center;
      background-size: cover;
      padding: 45px 55px;
      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }
      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }
      /deep/.el-textarea__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
        height: 120px;
        resize: none;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
      }

      /deep/.el-radio {
        color: #fff;
      }
      .my-el-form {
        height: calc(100% - 62px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
      }
      .form-tips {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .form-tips-list {
          display: flex;
          flex-wrap: wrap;
          .form-tip-item {
            min-width: 106px;
            padding: 0 13px;
            height: 38px;
            background: rgba(62, 144, 229, 0.11);
            border: 1px solid rgba(62, 144, 229, 0.4);
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #3e90e5;
            margin-right: 13px;
            margin-bottom: 13px;
            &:hover {
              opacity: 0.8;
            }
            img {
              width: 10px;
              height: 10px;
              margin-left: 9px;
              cursor: pointer;
            }
          }
          .add-tip-button {
            width: 79px;
            height: 38px;
            border: 1px solid rgba(56, 85, 118, 0.4);
            border-radius: 3px;
            font-size: 14px;
            line-height: 38px;
            text-align: center;
            color: #fff;
            cursor: pointer;
            span {
              margin-right: 6px;
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
        /deep/.tip-input {
          width: 88px;
          .el-input__inner {
            width: 100%;
          }
        }
        .img-box {
          display: flex;
          align-items: center;
          height: 40px;
          cursor: pointer;
          margin-left: 10px;
          .icon2-imgs {
            width: 29px;
            height: 29px;
          }
        }
      }
    }
  }
  /deep/.small-pop {
    .el-dialog__header {
      padding-top: 80px !important;
    }
    .el-dialog__headerbtn {
      top: 129px;
    }
  }
  .big-pop.adds {
    /deep/.el-dialog {
      width: 671px !important;
    }
  }
}
</style>
